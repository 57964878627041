import { API_ASDI_BASE_URL } from 'config/constants'
import { useEffect, useState } from 'react'

/* eslint-disable camelcase */
export interface DeBankTvlResponse {
  id: string
  chain: string
  name: string
  site_url: string
  logo_url: string
  has_supported_portfolio: boolean
  tvl: number
}

export const useGetStats = () => {
  const [data, setData] = useState<DeBankTvlResponse | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://openapi.debank.com/v1/protocol?id=bsc_pancakeswap')
        const responseData: DeBankTvlResponse = await response.json()

        setData(responseData)
      } catch (error) {
        console.error('Unable to fetch data:', error)
      }
    }

    fetchData()
  }, [setData])

  return data
}

export async function getContactContentChat() {
  try {
    const resp = await fetch(`https://gateway.asdi.foundation/utils/asdi-social-network?isGetAll=true`, {
      method: 'GET',
    })

    if (resp.status === 200) {
      const data = await resp.json()
      return data?.data
    }
  } catch (error) {
    console.error('~setGetSetting', error)
  }

  return null
}
